/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@use "../src/theme/animations.scss";
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Swiper CSS*/
@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';

.custom-popup{
    background-color: rgba(91,91,91,0.3);
    min-width: 200px;
    border-radius: 8px;
    backdrop-filter: blur(6px);
    .leaflet-popup-content-wrapper, .leaflet-popup-tip{
        background-color: transparent;
        img{
            transition: .3s ease;
            border-radius: 5px;
        }

        .glowing{
            -webkit-box-shadow: 0px 3px 71px -26px rgba(0, 255, 247, 0.75);
            -moz-box-shadow: 0px 3px 71px -26px rgba(0, 255, 247, 0.75);
            box-shadow: 0px 3px 71px -26px rgba(0, 255, 247, 0.75);
            padding: 5px;
        }

        

        .working-img{
            position: absolute;
            width: 40px;
            top: -10px;
            right: -10px;
        }
    }
}